<template>
  <div>
    <div id="header" class="z-50">
      <div class="w-full mx-auto bg-transparent items-center">
        <div class="root_nav cor_nav">
          <ul
            class="top_sub_nav lg:flex-row list-none lg:mr-auto relative"
            v-if="show"
            @click="zindex++"
            :style="{ 'z-index': 'var(--higherZIndex)' }"
          >
            <li
              class="flex items-center text-white text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase dropdown z-40 w-full"
              :style="isMinimize ? { display: 'none' } : { display: 'flex' }"
            >
              <CustomSelect
                v-if="!bottomMinimizer"
                :options="this.wellNamesOrg"
                :default="this.dWell"
                @input="selectWell($event)"
                class="text_xs correlation_bar"
                :well_disabled="true"
              />

              <CustomSelect
                v-if="
                  !bottomMinimizer && (this.wellNamesOrg != this.wellBoreNamesOrg)
                "
                :options="this.wellBoreNamesOrg"
                :default="this.dWellbore"
                @input="selectWellbore($event)"
                class="text_xs correlation_bar"
                :well_disabled="true"
              />

              <!-- <CustomSelect
                v-if="!bottomMinimizer"
                :options="this.logNames"
                :default="this.dLog"
                @input="selectLog($event)"
                class="w-50"
              /> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api/services";
import CustomSelect from "../Select/SelectComponent.vue";
import HideShow from "./HideShow.vue";

export default {
  props: {
    correlationIdx: [String, Number],
    onMinMax: Function,
    onMinMaxBck: Function,
    displayId: String,
    isMinimize: {
      type: Boolean,
      default: false,
    },
    bottomMinimizer: {
      type: Boolean,
      default: false,
    },
    displayDetails: {
      type: Object,
      default: {},
    },
    minimizedIndex: {
      type: Number,
      default: -1,
    },
    isDisplay: {
      type: Boolean,
      default: false,
    },
    widgetDetails: {
      type: Object,
      default: {},
    },
    widgetIndex: [Number],
    barIndex: [Number],
    widgetId: String,
    selectdWell: [Object],
  },
  components: {
    HideShow,
    CustomSelect,
  },
  computed: {
    wells() {
      setTimeout(() => {
        if (this.$store.state.rect.rects[this.correlationIdx]) {
          this.dWell =
            this.$store.state.rect.rects[this.correlationIdx]?.wellsToRelate[
              this.barIndex
            ].name;
          this.selectedWell = this.barIndex;

          for (let i in this.$store.state.rect.rects[this.correlationIdx]
            ?.wellsToRelate[this.barIndex]["wellbores"]) {
            this.selectedWellbore = i;
            this.dWellbore =
              this.$store.state.rect.rects[this.correlationIdx]?.wellsToRelate[
                this.barIndex
              ]["wellbores"][i].wellbore_name;
            break;
          }
          this.selectedLog = "depth";
          this.dLog = "depth";
          this.setDisplay();
        }
      }, 500);

      let tempWells = [];
      if (this.$store.state.rect.rects[this.correlationIdx]?.wellsToRelate)
        tempWells = [
          this.$store.state.rect.rects[this.correlationIdx].wellsToRelate[
            this.barIndex
          ],
        ];
      // return this.$store.state.data.wells;
      return this.$store.state.rect.rects[this.correlationIdx]?.wellsToRelate;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  data() {
    return {
      // zindex: 10000,
      wellSelectionObj: {},
      wellBoreSelectionObj: {},
      wellNames: [],
      wellNamesOrg: [],
      wellBoreNames: [],
      wellBoreNamesOrg: [],
      logNames: [],
      wellBores: [],
      selectedWell: null,
      selectedWellbore: null,
      selectedLog: null,
      wellIndex: 0,
      wellboreIndex: 0,
      logIndex: 0,
      dWell: "Well",
      dWellbore: "Wellbore",
      dLog: "Log",
      show: true,
      screen_name: "",
      isAlreadySaved: false,
      errcode: "",
      radioVal: "0",
      selected_srn: "",
      searchList: [],
      search_input: "",
      isSearching: false,
    };
  },
  beforeMount() {
    // this.$store.dispatch("data/getWells");
    this.wellNames = [];
    this.wellNamesOrg = [];
    for (let i in this.wells) {
      this.wellNames.push(
        i + (this.wells[i].rigname ? " - " + this.wells[i].rigname : "")
      );
      this.wellSelectionObj[this.wells[i].name] = i;
      this.wellNamesOrg.push(
        this.wells[i].name +
          (this.wells[i].rigname ? " - " + this.wells[i].rigname : "")
      );
    }
    //
    //  this.getWellNames(this.wells);
  },
  mounted() {
    for (let rect of this.$store.state.disp.rects) {
      if (rect["displayId"] == this.displayId) {
        if (rect.hasOwnProperty("savedId")) {
          this.isAlreadySaved = rect["savedId"];
          this.screen_name = rect["savedName"];
        }
      }
    }

    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "rect/setDisplay":
          if (mutation.payload.display == this.displayId) {
            try {
              this.selectedWell = mutation.payload.wellId
                ? mutation.payload.wellId
                : "Well";
              this.dWell = mutation.payload.wellId
                ? this.wells[mutation.payload.wellId].name +
                  (mutation.payload.rigName
                    ? " - " + mutation.payload.rigName
                    : "")
                : "Well";

              this.selectedWell = mutation.payload.wellId;

              this.dWellbore = mutation.payload.wellboreId
                ? this.wells[mutation.payload.wellId]["wellbores"][
                    mutation.payload.wellboreId
                  ].wellbore_name +
                  (mutation.payload.rigName
                    ? " - " + mutation.payload.rigName
                    : "")
                : "Wellbore";
              this.selectedWellbore = mutation.payload.wellboreId;
              this.wellBoreNames = [];
              this.wellBoreNamesOrg = [];
              for (let i in this.wells[mutation.payload.wellId]["wellbores"]) {
                this.wellBoreNames.push(
                  i +
                    (this.wells[mutation.payload.wellId].rigname
                      ? " - " + this.wells[mutation.payload.wellId].rigname
                      : "")
                );
                this.wellBoreNamesOrg.push(
                  this.wells[mutation.payload.wellId]["wellbores"][i]
                    .wellbore_name +
                    (this.wells[mutation.payload.wellId].rigname
                      ? " - " + this.wells[mutation.payload.wellId].rigname
                      : "")
                );
              }
              this.logNames = [];
              for (let i in this.wells[mutation.payload.wellId]["wellbores"][
                mutation.payload.wellboreId
              ]["logs"]) {
                this.logNames.push(i);
              }

              this.dLog = mutation.payload.logType
                ? mutation.payload.logType
                : "Log";
            } catch (error) {
              //
            }
          }
          break;

        default:
          //
          break;
      }
    });
  },
  methods: {
    onBlurSelect() {
      setTimeout(() => {
        this.isSearching = false;
      }, 200);
    },
    onSelectDb(db) {
      this.selected_srn = db;
      this.search_input = db.name;
      this.isSearching = false;
      this.searchList = this.srnList.filter(function (element) {
        return element.name.toLowerCase().indexOf(db.name.toLowerCase()) > -1;
      });
    },
    filterFunction(e) {
      this.isSearching = true;
      this.searchList = this.srnList.filter(function (element) {
        return (
          element.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        );
      });
    },
    reset() {
      this.item = {};
    },
    selectFromParentComponent1() {
      // select option from parent component
      this.item = this.options[0];
    },
    onFullScreen() {
      this.$store.dispatch("disp/fullScreen", {
        displayId: this.displayId,
      });
    },
    async onSaveScreen() {
      if (this.radioVal == "0" && !this.screen_name) {
        this.$toast.error("ENTER SCREEN NAME", {
          position: "top",
          duration: 2000,
        });
        this.errcode = "ENTER SCREEN NAME";
        return {};
      }
      if (this.radioVal == "0" && this.screen_name.length > 14) {
        this.$toast.error("NAME MUST BE LESS THAN 15 CHARACTERS", {
          position: "top",
          duration: 2000,
        });
        this.errcode = "NAME MUST BE LESS THAN 15 CHARACTERS";
        return {};
      }
      if (this.radioVal == "0") {
        let nameExists = await api.DashBoardServices.getNameExists({
          name: this.screen_name,
        });

        if (nameExists && nameExists.data) {
          this.errcode = "NAME ALREADY EXISTS";
          this.$toast.error("NAME ALREADY EXISTS", {
            position: "top",
            duration: 2000,
          });
          return {};
        }
      }
      //
      let displaySave = {};

      for (let rect of this.$store.state.disp.rects) {
        if (rect["displayId"] == this.displayId) {
          displaySave = {
            customerId: 1,
            name: this.screen_name,
            dbId: null,
            dbType: "screen", // 'Dashboard' / 'Screen' / 'Background'
            dashboard: null,
            screen: {
              width: rect.width,
              height: rect.height,
              top: rect.top,
              left: rect.left,
              zIndex: rect.zIndex,
              name: "display",
              background: [],
              widgets: [],
            },
          };
        }
      }

      let displayDtls = this.$store.state.disp.displays[this.displayId];
      if (displayDtls) {
        displaySave.screen.wellDetails = {
          logId: displayDtls.logId,
          logType: displayDtls.logType,
          max: displayDtls.man,
          min: displayDtls.min,
          table: displayDtls.table,
          wellboreId: displayDtls.wellboreId,
          wellboreState: displayDtls.wellboreState,
          wellId: displayDtls.wellId,
        };
      }

      let widgets = [];
      let bgs = {};
      let bgWidgets = {};
      for (let rect of this.$store.state.rect.rects) {
        // alert(
        //   rect["backgroundId"] +
        //     " " +
        //     rect["bgId"] +
        //     "" +
        //     rect["widgetId"] +
        //     " " +
        //     rect["name"]
        // );
        if (rect["displayId"] == this.displayId) {
          if (rect["name"] == "Background") {
            bgs[rect["backgroundId"]] = {
              name: "Background", // Background
              left: rect.left,
              top: rect.top,
              width: rect.width,
              height: rect.height,
              zIndex: rect.zIndex,
              color: rect.color,
              widgets: bgWidgets[rect["backgroundId"]]
                ? bgWidgets[rect["backgroundId"]]
                : [],
            };
          } else if (rect["bgId"]) {
            if (!bgs[rect["bgId"]]) {
              if (bgWidgets[rect["bgId"]]) {
                bgWidgets[rect["bgId"]].push({
                  ...rect,
                  name: rect.name, // Numeric / Table
                  label: [rect.title], // eg : 'DBTM', [GTF, MTF]
                  left: rect.left,
                  top: rect.top,
                  width: rect.width,
                  height: rect.height,
                  zIndex: rect.zIndex,
                  color: rect.color,
                });
              } else {
                bgWidgets[rect["bgId"]] = [
                  {
                    ...rect,
                    name: rect.name, // Numeric / Table
                    label: [rect.title], // eg : 'DBTM', [GTF, MTF]
                    left: rect.left,
                    top: rect.top,
                    width: rect.width,
                    height: rect.height,
                    zIndex: rect.zIndex,
                    color: rect.color,
                  },
                ];
              }
            } else {
              bgs[rect["bgId"]]["widgets"].push({
                ...rect,
                name: rect.name, // Numeric / Table
                label: [rect.title], // eg : 'DBTM', [GTF, MTF]
                left: rect.left,
                top: rect.top,
                width: rect.width,
                height: rect.height,
                zIndex: rect.zIndex,
                color: rect.color,
              });
            }
          } else {
            let otherProps = {};
            if (rect.name == "plotly-graph") {
              // //
              otherProps.mnemonicTag1 = rect.mnemonicTag1;
              otherProps.mnemonicTag3 = rect.mnemonicTag3;
              otherProps.mnemonicTag4 = rect.mnemonicTag4;
              otherProps.mnemonicTag2 = rect.mnemonicTag2;
            }
            if (rect.name == "trend") {
              otherProps.config = rect.plot;
            }

            widgets.push({
              ...rect,
              ...otherProps,
              name: rect.name, // Numeric / Table
              label: [rect.title], // eg : 'DBTM', [GTF, MTF]
            });
          }
        }
      }

      for (let bg in bgs) {
        displaySave.screen.background.push(bgs[bg]);
      }
      displaySave.screen.widgets = widgets;

      // if (this.isAlreadySaved) {
      //   displaySave.id = this.isAlreadySaved;
      // }
      if (this.radioVal == "1") {
        if (!this.selected_srn) {
          this.errcode = "SCREEN IS NOT SELECTED";
          this.$toast.error("SCREEN IS NOT SELECTED", {
            position: "top",
            duration: 2000,
          });
          return {};
        }
        displaySave.id = this.selected_srn._id;
        displaySave.name = this.selected_srn.name;
      }

      let response = await api.DashBoardServices.saveDashboard(displaySave);

      this.$store.dispatch("disp/changeDisplayName", {
        displayId: this.displayId,
        savedName: this.screen_name,
      });
      this.toggleModal();
      this.$toast.success("Screen Saved", {
        position: "top",
        duration: 2000,
      });
    },
    onMinimizeHandler() {
      // this.onMinMaxBck("maximize");
      // //
      this.$store.dispatch("disp/minimizeDisplay", {
        displayId: this.displayId,
      });
      this.$store.dispatch("disp/changeZToTop", {
        displayId: this.displayId,
      });
      // this.$store.dispatch("disp/minimizeRect", { displayId: this.displayId });
    },
    onMaximizeHandler() {
      // this.onMinMaxBck("maximize");
      if (this.isDisplay) {
        this.$store.dispatch("disp/maximizeDisplay", {
          displayId: this.displayDetails.displayId,
          minimizedIndex: this.minimizedIndex,
        });
      } else {
        this.$store.dispatch("rect/maximizeWidget", {
          displayId: this.widgetDetails.displayId,
          widgetId: this.widgetDetails.widgetId,
          minimizedIndex: this.minimizedIndex,
        });
      }

      // this.$store.dispatch("disp/maximizeRect", { displayId: this.displayId });
    },
    deleteDisplayHandler() {
      if (this.displayDetails.displayId) {
        this.$store.dispatch("disp/deleteDisplay", {
          displayId: this.displayDetails.displayId,
          minimizedIndex: this.minimizedIndex,
        });
      } else if (this.widgetDetails.displayId) {
        this.$store.dispatch("rect/deleteWidget", {
          displayId: this.widgetDetails.displayId,
          widgetId: this.widgetDetails.widgetId,
          minimizedIndex: this.minimizedIndex,
        });
      } else {
        this.$store.dispatch("disp/deleteDisplay", {
          displayId: this.displayId,
          minimizedIndex: false,
        });
        this.$store.dispatch("rect/deleteDisplay", {
          displayId: this.displayId,
          minimizedIndex: false,
        });
      }
    },
    getWellNames(wells) {
      // this.wellNames = Object.keys(wells);
      this.wellNames = [];
      this.wellNamesOrg = [];
      for (let i in wells) {
        this.wellNames.push(
          i + (wells[i].rigname ? " - " + wells[i].rigname : "")
        );
      }
    },
    selectWell(wellName) {
      this.wellBoreNames = [];
      this.wellBoreNamesOrg = [];
      if (wellName != "Well") {
        //
        wellName = wellName.split(" - ")[0];
        wellName = this.wellSelectionObj[wellName];

        this.selectedWell = wellName.split(" - ")[0];
        this.dWellbore = "Wellbore";
        this.dLog = "Log";
        for (let i in this.wells[wellName]["wellbores"]) {
          this.wellBoreNames.push(
            i +
              (this.wells[wellName].rigname
                ? " - " + this.wells[wellName].rigname
                : "")
          );
          this.wellBoreSelectionObj[
            this.wells[wellName]["wellbores"][i].wellbore_name
          ] = i;
          this.wellBoreNamesOrg.push(
            this.wells[wellName]["wellbores"][i].wellbore_name +
              (this.wells[wellName].rigname
                ? " - " + this.wells[wellName].rigname
                : "")
          );
        }
        // this.wellBoreNames = Object.keys(this.wells[wellName]["wellbores"]);
        // this.getWellboreNames(this.selectedWell);
      }
    },
    selectWellbore(wellboreName) {
      this.logNames = [];
      if (wellboreName != "Wellbore") {
        this.dLog = "Log";
        wellboreName = wellboreName.split(" - ")[0];
        wellboreName = this.wellBoreSelectionObj[wellboreName];
        this.selectedWellbore = wellboreName;
        this.logNames = Object.keys(
          this.wells[this.selectedWell]["wellbores"][this.selectedWellbore][
            "logs"
          ]
        );
        // this.getLogNames(this.selectedWellbore);
      }
    },
    selectLog(logName) {
      if (logName != "Log") {
        this.selectedLog = logName;
        this.setDisplay();
      }
    },
    getWellboreNames(wellName) {
      this.wellBoreNames = [];
      this.wellBoreNamesOrg = [];
      this.wellBoreNames = Object.keys(this.wells[wellName]["wellbores"]);
      // for (let i = 0; i < this.wells.length; i++) {
      //   if (this.wells[i].well_nm === wellName) {
      //     //
      //     this.wellIndex = i;
      //     for (let j = 0; j < this.wells[i].wellbores.length; j++) {
      //       this.wellBoreNames.push(this.wells[i].wellbores[j].wellbore_name);
      //     }
      //     //
      //   }
      // }
    },
    getLogNames(wellboreName) {
      //
      this.logNames = [];
      let wellbores = this.wells[this.wellIndex].wellbores;
      //
      for (let i = 0; i < wellbores.length; i++) {
        if (wellbores[i].wellbore_name === wellboreName) {
          //
          this.wellboreIndex = i;
          for (let j = 0; j < wellbores[i].logs.length; j++) {
            this.logNames.push(wellbores[i].logs[j].log_name);
          }
        }
      }
    },
    setDisplay(displayId) {
      if (
        this.wells[this.selectedWell]["wellbores"][this.selectedWellbore][
          "logs"
        ]["depth"] &&
        "depth" == this.selectedLog
      ) {
        this.$store.dispatch("rect/setRelationDisplay", {
          data: {
            ...this.wells[this.selectedWell]["wellbores"][
              this.selectedWellbore
            ]["logs"]["depth"],
            wellId: this.wells[this.selectedWell].well_id,
            wellboreId: this.selectedWellbore,
          },
          barIndex: this.barIndex,
          widgetIndex: this.correlationIdx,
          widgetId: this.widgetId,
        });
      } else {
        this.selectedLog = "Log";
        this.$toast.error("ONLY DEPTH LOG ALLOWED", {
          position: "top",
          duration: "1000",
        });
      }
      this.selectedLog = "depth";
    },
    async toggleModal() {
      let displayDtls = this.$store.state.disp.displays[this.displayId];
      if (!displayDtls) {
        this.$toast.error("Please Select The Well", {
          position: "top",
          duration: 2000,
        });
        return {};
      }

      this.errcode = "";
      if (
        document
          .getElementById("modal" + this.displayId)
          .classList.value.indexOf("hidden") > -1
      ) {
        for (let rect of this.$store.state.disp.rects) {
          if (rect["displayId"] == this.displayId) {
            if (rect.hasOwnProperty("savedId")) {
              this.isAlreadySaved = rect["savedId"];
              this.screen_name = rect["savedName"];
            }
          }
        }

        let response = await api.DashBoardServices.getSavedContentList({
          dbType: "screen",
        });
        this.selected_srn = "";
        this.srnList = response.data;
        this.searchList = response.data;
        this.search_input = "";
      }

      document
        .getElementById("modal" + this.displayId)
        .classList.toggle("hidden");
      // alert("model works")
    },
    dark() {
      this.$emit("dark");
    },
    light() {
      this.$emit("light");
    },
  },
  watch: {
    selectdWell(newValue) {
      setTimeout(() => {
        if (this.$store.state.rect.rects[this.correlationIdx]) {
          this.dWell =
            this.$store.state.rect.rects[this.correlationIdx]?.wellsToRelate[
              this.barIndex
            ].name;
          this.selectedWell = this.barIndex;

          for (let i in this.$store.state.rect.rects[this.correlationIdx]
            ?.wellsToRelate[this.barIndex]["wellbores"]) {
            this.selectedWellbore = i;
            this.dWellbore =
              this.$store.state.rect.rects[this.correlationIdx]?.wellsToRelate[
                this.barIndex
              ]["wellbores"][i].wellbore_name;
            break;
          }
          this.selectedLog = "depth";
          this.dLog = "depth";
          this.setDisplay();
        }
      }, 100);
    },
  },
};
</script>
<style scoped>
.bg_gray {
  background-color: #141414;
}

.bg_gray_600 {
  background-color: #2e2e2e;
}

.nav_bar {
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  font-size: 14px;
  /* font-weight: 500; */
  color: #ffffff50;
}

.ml-6rem {
  margin-left: 6.6rem;
}

.ml-4rem {
  margin-left: 4.4rem;
}

.nav_item {
  padding: 0.8rem 0.2rem;
}

.nav_item.active {
  opacity: 1;
  border-bottom: 2px solid #47a08e;
  color: white;
}

.text_light_gray {
  color: #ffffff80;
}

.bg_gray_400 {
  background-color: #414141;
}

.nav_bar_dashboard {
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  font-size: 12px;
  /* font-weight: 500; */
  color: #595959;
}

.mr_5 {
  margin-right: 2rem;
}

.mr_1 {
  margin-right: 1rem;
}

.nav_item_dashboard.active {
  color: white;
}

.text_light_gray {
  color: #959595;
}

.btn-dashboard-nav {
  background-color: #414141;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 11px;
  padding-top: 1px;
  padding-right: 0.75rem;
  padding-bottom: 1px;
  padding-left: 0.75rem;
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  /* font-weight: 400; */
}

.dropdown-border-bottom {
  border-bottom: 1px solid #707070;
}

.dropdown_label {
  color: #ffffff70;
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  /* font-weight: 400; */
  font-size: 14px;
}

.bg_dark_gray {
  background: #414141;
}

.top-16 {
  top: 3.9rem;
}

.text_xs {
  font-size: 8px;
  word-wrap: break-word;
  font-weight: 200;
}

.top_sub_nav {
  justify-content: space-around;
  width: 100%;
  height: 25px;
}

.root_nav {
  display: block;
  justify-content: flex-end;
  width: 100%;
  background: var(--navBar2Bg);
  transition: var(--transition);
  /* var(--root_nav) !important; */
}

.cor_nav {
  background: black;
}

.dropdown:hover .dropdown-menu {
  display: block !important;
  z-index: 999;
  width: 100%;
}

/* @media (max-width: 723px) {
  .root_nav{
    display: none;
  }
} */
.line_ {
  inline-size: 155px;
  overflow-wrap: break-word;
}

/* .model_bg {
  background: #242b34e8;
} */

.isBlur {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
  z-index: 99999999999;
}

.modal_width {
  width: 22%;
}

.modal_body {
  font-size: 10px;
  padding: 0 15px 18px 15px;
}

.rounded-t {
  padding-bottom: 10px;
  font-size: 10px;
}

.tooltip {
  background: #000000;
}

.hide_show_btn {
  z-index: 100;
}
</style>
