<template>
  <div
    :id="displayId"
    :style="{
      height: this.height + 'px',
      width: this.width + 'px',
      overflow: 'hidden',
      background:
        this.$store.state.rect.darkmode == true
          ? this.cardBackground
          : '#FFFFF7',
    }"
    @contextmenu="this.setContextId"
    @click="foreground()"
    :class="'bg_purple bg shadow-lg cust_shadow container_'"
  >
    <AdminSubNavbar
      :onMinMaxBck="onMinMax"
      :displayId="this.displayId"
      :isMinimize="isMinimize"
      :width="width"
      :height="height"
      :isTaD="this.isTaD"
      :isLwd="this.isLwd"
      :isAlertScreen="this.isAlertScreen"
      :displayIndex="displayIndex"
      :isCorrelation="this.isCorrelation"
      :isReplay="isReplay"
      :isCorrelationMap="isCorrelationMap"
      @clickedFullScreen="clickedFullScreen"
    />

    <Maindash
      :width="this.width"
      :height="this.height"
      :displayId="this.displayId"
      :isMinimize="this.isMinimize"
      :isTaD="this.isTaD"
      :isLwd="this.isLwd"
      :isCorrelation="this.isCorrelation"
      :displayIndex="displayIndex"
      v-if="width > width ? width - 10 : width"
      :isScreenMaximized="isScreenMaximized"
    ></Maindash>
  </div>
</template>

<script>
import Maindash from "../../Maindash.vue";
import AdminSubNavbar from "../../Navbars/AdminSubNavbar.vue";
import fore from "../../../assets/img/foreground.png";
import back from "../../../assets/img/background.png";
import CorrelationBar from "../../Navbars/CorrelationBar.vue";
var data = {
  soundurl: "http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3",
};
export default {
  name: "display-widget",

  components: {
    CorrelationBar,
    Maindash,
    AdminSubNavbar,
  },

  props: {
    displayIndex: Number,
    index: Number,
    displayId: String,

    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 350,
    },
    left: {
      type: Number,
      default: 200,
    },
    right: {
      type: String,
      default: "350",
    },
    cardBackground: {
      type: String,
      default: "#3f3f3f",
    },
    onMinMax: Function,
    isMinimize: {
      type: Boolean,
      default: false,
    },
    isTaD: {
      type: Boolean,
      default: false,
    },
    isCorrelation: {
      type: Boolean,
      default: false,
    },
    isCorrelationMap: {
      type: Boolean,
    },
    isLwd: {
      type: Boolean,
      default: false,
    },
    isAlertScreen: {
      type: Boolean,
      default: false,
    },
    isReplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardWidgetConfig: this.numericWidgetConfig,
      isPlayed: false,
      fore,
      back,
      selectedWell:'',
      selectedWellBore:'',
      isScreenMaximized: false,
    };
  },
  mounted() {
    try {
      let listEl = document.getElementById(this.dashId);
      this.listWidth = listEl.clientWidth;
      this.listHeight = listEl.clientHeight;
      window.addEventListener("keypress", (e) => {
        //
        // if (e.key == "Delete" && this.activatedIndex > -1) {
        //   this.$store.dispatch("disp/deleteWidget", { id: this.activatedIndex });
        //   this.activatedIndex = -1
        // }
      });
      window.addEventListener("resize", () => {
        this.listWidth = listEl.clientWidth;
        this.listHeight = listEl.clientHeight;
      });
    } catch (error) {
      //
    }

    // setTimeout(() => {
    //   this.rerender("darkDark");

    // }, 0)
  },
  methods: {
    clickedFullScreen(){
      if(!this.$store.state.data.main_nav_show){
        this.isScreenMaximized = true;
      }
      else{
        this.isScreenMaximized = false;
      }
    },
    foreground() {
      this.$store.dispatch("disp/changeZToTop", {
        displayId: this.displayId,
      });
    },
    setContextId() {
      if (!this.$store.state.disp.contextId) {
        this.$store.dispatch("disp/setContextId", {
          contextId: this.displayId,
          contextType: "display",
        });
      }
    },
    resizable() {
      this.toggleResizable();
      // console.log('_______rect_colors___', this.$store.state.rect.darkmode)
      //   return this.$store.state.rect.darkmode;
    },
    change() {
      this.toggleDraggable();
    },
  },
  computed: {},
};
</script>

<style >
.bg {
  background-color: var(--sidebarbg) !important;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  transition: var(--transition);
}
</style>